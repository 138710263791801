import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const style = {
    color: "white",
};


export default function TabsButton() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleScrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const elementRect = element.getBoundingClientRect();
            const absoluteElementTop = elementRect.top + window.pageYOffset;
            const middle = absoluteElementTop - (window.innerHeight / 2) + 300;
            window.scrollTo({ top: middle, behavior: 'smooth' });
        }
    };

    return (<Box sx={{ maxWidth: { xs: 350, sm: 500 } }}>
        <Tabs
            selectionFollowsFocus
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={true}
            aria-label="scrollable prevent tabs example"
        >
            <Tab value={0} onClick={() => handleScrollToElement('info')} style={style} label="Головна" />
            <Tab value={1} onClick={() => handleScrollToElement('services')} style={style} label="Послуги" />
            <Tab value={2} onClick={() => handleScrollToElement('contacts')} style={style} label="Контакти" />
            <Tab value={3} onClick={() => handleScrollToElement('maps')} style={style} label="Карта" />
        </Tabs>
    </Box>);
}
