import React, {Component} from 'react';
import '../PagesStyles.css'

class Info extends Component {
    render() {
        return (<div id='info'>
                <p className="headerText mt-5">Спеціалізований ремонт тягачів та напівпричепів</p>
                <div className="row Pages_total">
                    <div className="aboutAsText">
                        <p className="ml-5  mr-4">    &#128736; Кіндрат Trans - надійнй партнер у світі перевезень та ремонту вантажних автомобілів! Наш спеціалізований автосервіс розташований у місті Перемишляни та готовий допомогти вам з будь-якими проблемами, пов'язаними з вашою грузовою технікою.
                        </p>
                        <p className="ml-5  mr-4">    &#128736; Автомайстерня спеціалізується на ремонті ходової
                            частини автомобілів європейського виробництва
                            таких як: DAF, Mercedes, MAN, Scania, Reno та інших.
                        </p>
                        <p className="ml-5  mr-4"> &#128736; Грузова техніка оснащена складною технічною системою, тому для її ремонту потрібні
                            вузьконаправлені та досвідчені спеціалісти,
                            які і парцюють в автомайстерні TIR. Вони допоможуть розібратись з будь-якою проблемою
                            повзянаю з вашою технікою в найкоротші строки.
                        </p>
                        <p className="ml-5  mr-4">    &#128736; Компанія також пропонує продаж запчастин для вантажних автомобілів.
                        </p>
                    </div>
                </div>
            </div>

        );
    }
}

export default Info;