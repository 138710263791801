import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import '../PagesStyles.css'

const containerStyle = {
    width: '100%', height: '500px'
};

const center = {
    lat: 49.674394, lng: 24.559348
}

const onClick = () => {
    window.open("https://www.google.com.ua/maps/dir//49.6744603,24.55936/@49.6743283,24.5574077,482m/data=!3m1!1e3!4m2!4m1!3e0")
};


function MyMap() {
    return  (
    <div id={"maps"}>
        <p className="headerText mt-5">Ми на мапі</p>
        <div className={'Pages_total'}>
            <iframe
                width="100%"
                height="350"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                id="gmap_canvas"
                src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=ua&amp;q=49.674444, 24.559333&amp;t=k&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
            </iframe>
        </div>
    </div>)
}

export default React.memo(MyMap)
