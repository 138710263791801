import Accordion from 'react-bootstrap/Accordion';
import WorkTable from "../../components/UI/Table/workTable";
import React from "react";
import '../PagesStyles.css'

const style = {
    marginTop: 3, padding: 0,

};

function Services() {
    return (<div id={'services'}>
        <p className="headerText mt-3">Ми виконуємо ремонт</p>
        <div className="Pages_total">
            <Accordion defaultActiveKey={['3']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Ходової</Accordion.Header>
                    <Accordion.Body style={style}>
                        <WorkTable
                            rows={[
                                {name: 'Перевірка стану ходової системи автомобіля'},
                                {name: 'Ремонт підвіски та амортизаторів'},
                                {name: 'Заміна гальмівних систем'},
                                {name: 'Ремонт та заміна рульового управління'},
                                {name: 'Ремонт або заміна передніх та задніх мостів'},
                                {name: 'Діагностика та виявлення несправностей'},
                                {name: 'Ремонт та заміна пружин та стійок підвіски'},
                                {name: 'Ремонт або заміна кульових опор та інших елементів ходової.'},
                            ]}
                        />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Мотору</Accordion.Header>
                    <Accordion.Body style={style}>
                        <WorkTable
                            rows={[
                                {name: 'Перевірка стану мотора та його компонентів'}, 
                                {name: 'Діагностика та виявлення несправностей'}, 
                                {name: 'Ремонт та заміна поршнів та кілець'}, 
                                {name: 'Заміна ременя ГРМ'}, 
                                {name: 'Ремонт та заміна клапанів'}, 
                                {name: 'Ремонт головки блоку циліндрів'}, 
                                {name: 'Заміна масляного насоса'}, 
                                {name: 'Ремонт та заміна кореневих підшипників'}, 
                                {name: 'Ремонт та заміна шатунних підшипників'}, 
                                {name: 'Ремонт та заміна масляних каналів.'}, 
                                {name: 'Заміна фільтрів'}, 
                          ]}
                        />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Напівпричіпів</Accordion.Header>
                    <Accordion.Body style={style}>
                        <WorkTable
                            rows={[
                                {name: 'Перевірка стану напівпричіпу та його компонентів'}, 
                                {name: 'Ремонт та заміна осей та підвіски'}, 
                                {name: 'Ремонт кузова напівпричіпу'}, 
                                {name: 'Заміна гальмівних колодок та барабанів'}, 
                                {name: 'Ремонт повітряних та гідравлічних систем'}, 
                                {name: 'Ремонт та заміна електричної системи'}, 
                                {name: 'Діагностика та виявлення несправностей'}, 
                                {name: 'Ремонт та заміна бортових стінок та даху напівпричіпу'}, 
                                {name: 'Заміна амортизаторів'}, 
                                {name: 'Ремонт системи розвантаження.'},
                            ]}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    </div>);
}

export default Services;